import React, { useContext, useState, useEffect } from 'react'
import SidebarElement from './SidebarElement'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { AuthenticationContext } from '../../../Auth/authentication.context'
import { Carousel } from 'primereact/carousel'
import server from '../../../server'

const PharmacySidebarContent = () => {
  const navigate = useNavigate()
  const { status } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(false)
  const [advertisements, setAdvertisements] = useState([])
  const [nodes] = useState([
    {
      key: '1',
      label: 'Dashboard',
      link: '/',
      icon: 'mdi mdi-home',
    },
    {
      key: '2',
      label: 'Profile',
      link: '/pharmacy/profile',
      icon: 'mdi mdi-account',
    },
    {
      key: '3',
      label: 'Orders',
      link: '/pharmacy/orders',
      icon: 'mdi mdi-file-document-multiple',
    },
    {
      key: '4',
      label: 'Manufacturers',
      link: '/pharmacy/manufacturers',
      icon: 'mdi mdi-factory',
    },
    {
      key: '5',
      label: 'Logout',
      link: 'logout',
      icon: 'mdi mdi-logout',
    },
  ])

  const { collapsed, module } = useContext(AuthenticationContext)
  const [userModule, setUSerModule] = useState('')

  useEffect(() => {
    if (module) {
      setUSerModule(module)
    }
  }, [module])
  const statusButtonHide = (label) => {
    switch (label) {
      case 'Orders':
        return parseInt(status) < 3 ? true : false
      case 'Manufacturers':
        return parseInt(status) < 1 ? true : false
      default:
        return false
    }
  }

  const getAdvertisements = () => {
    setLoading(true)
    server
      .get('/ads', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        const ads = response?.data?.ads || []
        console.log('adsaaaa', ads)

        setAdvertisements(ads)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching advertisements:', error)
        setLoading(false)
      })
  }

  const adsTemplate = (advertisements) => {
    return (
      <div>
        <div
          style={{
            border: 'none',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            // margin: 'auto',
            margin : '15px',
            height: '300px ',

            width: '210px',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              padding: '10px',
            }}
          >
            {advertisements.image && (
              <img
                src={advertisements.image}
                alt={advertisements.title}
                style={{
                  width: '100%',
                  height: '100px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            )}
          </div>
          <div
            style={{
              padding: '10px',
            }}
          >
            <p className='fw-bold  mb-1' style={{ fontSize: '14px' }}>
              {advertisements.title}
            </p>
            <p className='text-muted mb-3' style={{ fontSize: '14px' }}>
              {advertisements.message}
            </p>
            {advertisements?.link ? (
              <a
                href={`https://${advertisements.link}`}
                className='text-decoration-none text-primary'
                style={{ fontSize: '14px', padding: '0px' }}
              >
                Learn More →
              </a>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getAdvertisements()
  }, [])
  return (
    <>
      <li>
        {collapsed ? (
          userModule === 'Origins Direct' && (
            <a
              href='/pharmacy/add-orders'
              className={`${status < 3 ? 'status-hidden' : ''} has-arrow`}
            >
              <i className='mdi mdi-pen'></i>
              <span className=''>New Order</span>
            </a>
          )
        ) : (
          <div
            className={`${
              status < 3 ? 'status-hidden' : ''
            } d-flex justify-content-center w-100 p-2`}
          >
            {userModule === 'Origins Direct' && (
              <Button
                type='button'
                label='Create New Order'
                onClick={() => navigate('/pharmacy/add-orders')}
              />
            )}
          </div>
        )}
      </li>
      {nodes.map((menuItem) =>
        !statusButtonHide(menuItem.label) ? (
          <SidebarElement
            key={menuItem.key}
            name={menuItem.label}
            link={menuItem.link}
            iconClass={menuItem.icon}
            notifications={menuItem.notifications}
            children={menuItem.children}
          />
        ) : null
      )}

      <li>
        {!collapsed && (
          <div className='card flex justify-content-center bg-transparent shadow-none'>
            <Carousel
              value={advertisements}
              numVisible={1}
              numScroll={1}
              verticalViewPortHeight='2400px'
              itemTemplate={adsTemplate}
              showIndicators={false}
            />
          </div>
        )}
      </li>
    </>
  )
}

export default PharmacySidebarContent
